($ => {
	if ($.carousel) {
		ui.warn('ERROR: you are including carousel.js multiple times');
		return;
	}
	const carousel = {
		create($element, options) {
			let instance = $element.data('carouselInstance');
			if (instance) {
				instance.destroy();
			}
			instance = Object.create(this.proto);
			const config = instance._generateConfig($element, options);
			instance.config = config;
			instance.$element = $element;
			instance.$inner = $element.find('.carouselInner');
			if (instance.$inner.length !== 1) {
				ui.warn(`You must have a child element with the class ".carouselInner" inside "#${$element.attr('id')}".`);
				return false;
			}
			instance.carouselId = $element.attr('id');
			if (!instance.carouselId) {
				instance.hasDynamicId = true;
				instance.carouselId = `carousel-${ui.getRandomNumber()}`;
				$element.attr('id', instance.carouselId);
			}
			$element.addClass(instance._generateElementClasses(config));
			instance.viewableWidth = instance.$inner.outerWidth(false);
			instance.timeout = {};
			instance.slides = instance._initSlides();
			instance.currentSlide = instance.slides[instance._getNewSlideIndex(config.firstSlide)];
			instance.controls = instance._generateControls();
			instance.bindControls();
			instance._savePositions();
			instance.goToSlide(config.firstSlide, true);
			instance._addEvents();
			instance.currentPosition = 0;
			if (config.autoscroll) {
				instance.autoscroll = {
					state: 'init'
				};
				if (config.playOnLoad) {
					instance.play();
				} else {
					instance.pause();
				}
			}
			$element
				.removeClass('loading')
				.data('carouselInstance', instance);
			return instance;
		},
		proto: {
			_addEvents() {
				this._addResizeEvent();
				if (this.config.autoscroll) {
					this._addMouseEvents();
				}
				if (!this.config.disableTouchEvents) {
					this._addTouchEvents();
				}
			},
			_addMouseEvents() {
				this.$element
					.on('mouseover.carousel', () => {
						if (this.autoscroll.state === 'playing') {
							this.pause('mouseover');
						}
					})
					.on('mouseleave.carousel', () => {
						if (this.autoscroll.state === 'mouseover') {
							this.play();
						}
					})
					.on('focusin.carousel', () => {
						if (this.autoscroll.state === 'playing') {
							this.pause('focus');
						}
					})
					.on('focusout.carousel', () => {
						if (this.autoscroll.state === 'focus') {
							this.play();
						}
					});
			},
			_addResizeEvent() {
				const $window = $(window);
				const timeout = this.timeout;
				let windowHeight = $window.outerHeight(false);
				let windowWidth = $window.outerWidth(false);
				$window.on(`resize.carousel.${this.carouselId}`, ui.throttle(() => {
					const { autoscroll } = this.config;
					if (autoscroll && this.autoscroll.state === 'playing') {
						this.pause('resize');
					}
					clearTimeout(timeout.resize);
					timeout.resize = setTimeout(() => {
						const newHeight = $window.outerHeight(false);
						const newWidth = $window.outerWidth(false);
						if (windowWidth !== newWidth || windowHeight !== newHeight) {
							this._updateSlidePositioning();
							this._savePositions();
							this.goToSlide(this.currentSlide.index, true);
							windowHeight = $window.outerHeight(false);
							windowWidth = $window.outerWidth(false);
						}
						if (autoscroll && this.autoscroll.state === 'resize') {
							this.play();
						}
					}, 200);
				}, 100));
			},
			_addTouchEvents() {
				const $inner = this.$inner;
				const { fullWidth, autoscroll } = this.config;
				const minDistance = 15; /* "right" number for mobile chrome */
				let touchStartTime;
				let isScrolling;
				let innerLeftPosition = 0;
				let change = 0;
				let startX = 0;
				let startY = 0;
				let currentX = 0;
				let currentXDistance = 0;
				/* These touch events have bound to $element but delegated to $inner.
				Certain iOS devices (iPhone 5, iPhone 6s) do not trigger these events
				when they are bound directly to $inner. */
				this.$element
					.on('touchstart.carousel', $inner, e => {
						touchStartTime = new Date().getTime();
						isScrolling = undefined;
						startX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
						startY = e.originalEvent.touches ? e.originalEvent.touches[0].pageY : e.pageY;
						innerLeftPosition = fullWidth ? this.currentPosition / 100 * this.viewableWidth : this.currentPosition;
					})
					.on('touchmove.carousel', $inner, e => {
						currentX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
						change = currentX - startX;
						currentXDistance = startX === 0 ? 0 : Math.abs(change);
						let newPosition;
						if (fullWidth) {
							change = change / this.viewableWidth * 100;
							newPosition = (innerLeftPosition / this.viewableWidth * 100) + change;
						} else {
							newPosition = innerLeftPosition + change;
						}
						const newCSS = this._generateAnimationCss(newPosition, {}, true);
						$inner.css(newCSS);
						if (typeof isScrolling === 'undefined') {
							const currentY = e.originalEvent.touches ? e.originalEvent.touches[0].pageY : e.pageY;
							const currentYDistance = startY === 0 ? 0 : Math.abs(currentY - startY);
							isScrolling = !!(isScrolling || currentXDistance < currentYDistance);
						}
						if (!isScrolling) {
							e.preventDefault();
							if (autoscroll && currentXDistance > minDistance) {
								this.pause();
							}
						}
					})
					.on('touchend.carousel', $inner, () => {
						/* Make sure swipe is greater than minDistance */
						if (!isScrolling && currentXDistance > minDistance) {
							const touchEndTime = new Date().getTime();
							const touchDuration = Math.abs(touchEndTime - touchStartTime);
							if (fullWidth || touchDuration < 340) {
								if (currentX < startX) {
									this.goToSlide('next');
								} else if (currentX > startX) {
									this.goToSlide('previous');
								}
							} else {
								this.goToSlide(this._getClosestSlide(innerLeftPosition + change));
							}
						} else {
							/* Don't change slides when scrolling */
							$inner.css(this._generateAnimationCss(this.currentPosition, {}));
						}
					});
			},
			bindControls() {
				this.controls = this._generateControlsList();
				const { $allGoToSlideBtns, $pausePlayBtns } = this.controls;
				const { autoscroll, showPausePlayBtns } = this.config;
				if (autoscroll && showPausePlayBtns && $pausePlayBtns.length) {
					$pausePlayBtns.on('click.carousel', () => {
						if (this.autoscroll.state !== 'paused') {
							this.pause();
						} else {
							this.play();
						}
					});
				} else if (!autoscroll && $pausePlayBtns.length) {
					ui.warn(`The carousel you are trying to bind a pause / play button to (#${this.carouselId}) is not currently set to autoscroll. To make it autoscroll you will need to destroy and re-create it passing the 'autoscroll' setting.`);
				}
				if ($allGoToSlideBtns.length) {
					$allGoToSlideBtns.on('click.carousel', e => {
						if (!this.$element.hasClass('carouselAnimating')) {
							if (autoscroll) {
								this.pause();
							}
							const $btn = $(e.target).closest('[data-carousel-slide]');
							this.goToSlide($btn.attr('data-carousel-slide'));
						}
					});
				}
			},
			destroy() {
				const { activeSlideClass, activeBtnClass, autoscroll } = this.config;
				clearTimeout(this.timeout.animate);
				clearTimeout(this.timeout.resize);
				if (autoscroll) {
					clearInterval(this.autoscroll.interval);
				}
				$(window).off(`.carousel.${this.carouselId}`);
				this.$element
					.off('.carousel')
					.removeAttr('tabindex')
					.removeClass('loading carouselAnimating carouselPaused carouselPlaying carouselFullWidth carouselVariedHeight carouselWithPreview')
					.removeData('carouselInstance');
				if (this.hasDynamicId) {
					this.$element.removeAttr('id');
				}
				this.currentSlide.$element.removeClass(activeSlideClass);
				this.$inner
					.find('.carouselSlide')
					.removeAttr('aria-hidden')
					.find(ui.tabbableTags)
					.removeAttr('tabindex');
				/* Remove controls */
				const { $allGoToSlideBtns, $defaultControls, $pausePlayBtns } = this.controls;
				$allGoToSlideBtns
					.removeClass(activeBtnClass)
					.off('click.carousel');
				$pausePlayBtns
					.removeClass('iconPlay iconPause') /* reset custom btns */
					.off('click.carousel');
				$defaultControls.remove();
			},
			_disableBtn(...args) {
				args.forEach($elem => $elem
					.addClass(this.config.disabledBtnClass)
					.attr('disabled', 'disabled')
				);
			},
			_disableBtns(overrideInfinite = false) {
				const { $allGoToSlideBtns, $pausePlayBtns } = this.controls;
				const { disabledBtnClass, infinite, continuous } = this.config;
				$allGoToSlideBtns
					.add($pausePlayBtns)
					.removeClass(disabledBtnClass)
					.removeAttr('disabled');
				if (!continuous && (overrideInfinite || !infinite)) {
					const { $element, currentSlide, lastSlide } = this;
					const { $nextBtns, $firstBtns, $lastBtns, $prevBtns } = this.controls;
					const { carouselWidth, showPausePlayBtns } = this.config;
					$allGoToSlideBtns.each((i, el) => {
						const $slideBtn = $(el);
						const goToIndex = parseInt($slideBtn.attr('data-carousel-slide'), 10);
						if (!isNaN(goToIndex) && goToIndex > this.slides.length - 1) {
							this._disableBtn($slideBtn);
						}
					});
					if (!showPausePlayBtns && $pausePlayBtns.length) {
						this._disableBtn($pausePlayBtns);
					}
					if (carouselWidth <= $element.outerWidth(false)) {
						this._disableBtn($allGoToSlideBtns);
					} else if (currentSlide.index === 0) {
						this._disableBtn($prevBtns, $firstBtns);
					} else if (currentSlide.index === lastSlide.index) {
						this._disableBtn($nextBtns, $lastBtns);
					}
				}
			},
			_fixPositionsIfNeeded() {
				const elementWidthHasChanged = this.viewableWidth !== this.$inner.outerWidth(false);
				if (elementWidthHasChanged) {
					this._updateSlidePositioning();
					this._savePositions();
				}
			},
			_generateAnimationCss(position, cssObj = {}, disableTransitions = false) {
				const scrollTime = this.config.scrollTime;
				const transition = disableTransitions ? 'none' : `transform ${scrollTime}ms, height ${scrollTime}ms`;
				const units = this.config.fullWidth ? '%' : 'px';
				const transform = `translateX(${position}${units})`;
				this.currentPosition = position;
				return Object.assign(cssObj, {
					transition,
					'-webkit-transition': transition,
					transform,
					'-webkit-transform': transform
				});
			},
			_generateConfig($element, options) {
				const config = Object.assign({}, carousel.staticProperties.defaults, options);
				if (config.fullWidth) {
					/* fullWidth carousels should always scroll by 1 */
					config.slidesPerScroll = 1;
				} else if (config.carouselHeight === 'varied') {
					ui.warn(`CarouselHeight on carousel "#${this.carouselId}" should not be set to varied unless it is a full width carousel.`);
					config.carouselHeight = 'auto';
				}
				if (config.showPausePlayBtns && !config.autoscroll) {
					ui.warn(`Autoscroll must be enabled in order to view pause/play buttons for #${this.carouselId}`);
					config.showPausePlayBtns = false;
				}
				if (config.infinite) {
					/* set first slide to be the middle of 3 slides */
					config.firstSlide = 1;
					/* Disable pagination for an infinite carousel */
					if (config.pagination !== null) {
						ui.warn(`Pagination is not available for infinite carousels (#${this.carouselId})`);
						config.pagination = null;
					}
				}
				return config;
			},
			_generateControls() {
				const { carouselId, $element, slides } = this;
				const { showPausePlayBtns, pausePlayBtnClass, pagination, showPrevNextBtns } = this.config;
				let controlsStringBuilder = '';
				const hideClass = showPrevNextBtns === 'desktop' ? ' hide480' : '';
				if (showPrevNextBtns) {
					controlsStringBuilder += `<button class="carouselNextBtn icon iconArrowRight" type="button" data-carousel-slide="next" data-carousel-id="${carouselId}"><span class="hideVisually">${ui.localize(carousel.staticProperties.langSets, 'nextBtn')}</span></button>`;
					controlsStringBuilder += `<button class="carouselPreviousBtn icon iconArrowLeft" type="button" data-carousel-slide="previous" data-carousel-id="${carouselId}"><span class="hideVisually">${ui.localize(carousel.staticProperties.langSets, 'previousBtn')}</span></button>`;
				}
				if (showPausePlayBtns) {
					controlsStringBuilder += `<button class="${pausePlayBtnClass} ancBtn icon iconPause silver sml" type="button" data-carousel-id="${carouselId}"><span class="hideVisually">${ui.localize(carousel.staticProperties.langSets, 'playBtn')}</span></button>`;
				}
				if (pagination) {
					controlsStringBuilder += this._generatePaginationString(slides.length);
				}
				const controls = {};
				if (showPausePlayBtns || pagination || showPrevNextBtns) {
					const $defaultControls = $(`<div class="carouselControlsDefault ${hideClass}">${controlsStringBuilder}</div>`);
					$element.append($defaultControls);
					controls.$defaultControls = $defaultControls;
				}
				return controls;
			},
			_generateControlsList() {
				const forCarousel = `[data-carousel-id="${this.carouselId}"]`;
				return Object.assign({}, this.controls, {
					$firstBtns: $(`${forCarousel}[data-carousel-slide="first"]`),
					$allGoToSlideBtns: $(`${forCarousel}[data-carousel-slide]`),
					$lastBtns: $(`${forCarousel}[data-carousel-slide="last"]`),
					$nextBtns: $(`${forCarousel}[data-carousel-slide="next"]`),
					$pausePlayBtns: $(`${forCarousel}.${this.config.pausePlayBtnClass}`),
					$prevBtns: $(`${forCarousel}[data-carousel-slide="previous"]`)
				});
			},
			_generateElementClasses(config) {
				const classList = ['carousel', 'loading'];
				if (config.fullWidth) {
					classList.push('carouselFullWidth');
					if (config.carouselHeight === 'varied') {
						classList.push('carouselVariedHeight');
					}
				}
				/* Add style class for carousel previews */
				if (config.showPreviews) {
					classList.push('carouselWithPreview');
				}
				return classList.join(' ');
			},
			_generatePaginationString(slideLength) {
				const { pagination } = this.config;
				let paginationStringBuilder = `<span class="carouselPagination carouselPagination${ui.ucfirst(pagination)}">`;
				for (let i = 0; i < slideLength; i++) {
					paginationStringBuilder += `<button class="ancBtn silver sml" type="button" data-carousel-slide="${i}" data-carousel-id="${this.carouselId}"><span class="hideVisually">${ui.localize(carousel.staticProperties.langSets, 'slide')} </span>${pagination === 'numbers' ? i + 1 : ''}</button>`;
				}
				paginationStringBuilder += '</span>';
				return paginationStringBuilder;
			},
			_getClosestSlide(newPosition) {
				const adjustedPosition = this.config.fullWidth ? newPosition / this.viewableWidth * 100 : newPosition;
				let result = 0;
				let closest = Math.abs(adjustedPosition);
				this.slides.every(slide => {
					const diff = Math.abs(slide.carouselPosition - adjustedPosition);
					if (diff <= closest) {
						closest = diff;
						result = slide.index;
						return true;
					}
					return false;
				});
				return result;
			},
			_getNewSlideIndex(index) {
				const lastSlideIndex = this.lastSlide ? this.lastSlide.index : this.slides.length - 1; /* last slide set in _savePositions() */
				/* Attempt to scroll the length of the sides if slidesPerScroll === page */
				const slidesToScroll = this.config.slidesPerScroll === 'page' ? this.slides.length : this.config.slidesPerScroll;
				let newSlideIndex = index;
				switch (newSlideIndex) {
					case 'next':
						newSlideIndex = this._getNextSlideIndex(slidesToScroll);
						break;
					case 'previous':
						newSlideIndex = this._getPrevSlideIndex(slidesToScroll);
						break;
					case 'first':
						newSlideIndex = 0;
						break;
					case 'last':
						newSlideIndex = lastSlideIndex;
						break;
					default:
						newSlideIndex = parseInt(newSlideIndex, 10);
						/* newSlideIndex should always be a number at this point. */
						if (isNaN(newSlideIndex)) {
							ui.warn(`'${index}' is not a valid slide.  Please check the data attribute 'data-carousel-slide' and make sure it's valid.`);
							return;
						}
						/* Restrict newSlideIndex to be between 0 (firstSlide) and lastSlide */
						if (newSlideIndex < 0) {
							newSlideIndex = 0;
						} else if (newSlideIndex > lastSlideIndex) {
							newSlideIndex = lastSlideIndex;
						}
				}
				return newSlideIndex;
			},
			_getNextSlideIndex(slidesToScroll) {
				const { currentSlide, lastSlide, slides } = this;
				const { fullWidth, continuous } = this.config;
				let nextSlideIndex = currentSlide.index;
				if (continuous && currentSlide.index === lastSlide.index) {
					nextSlideIndex = 0;
				} else if (fullWidth) {
					nextSlideIndex = Math.min(currentSlide.index + slidesToScroll, slides.length - 1);
				} else {
					const currentSlidePosition = currentSlide.carouselPosition;
					const maxSlideIndex = Math.min(currentSlide.index + slidesToScroll, lastSlide.index);
					let inView = true;
					/* Iterate through each of the slides after the current slide until you either hit the maxSlideIndex or the first slide that is not fully displayed in the carousel */
					while (nextSlideIndex < maxSlideIndex && inView) {
						nextSlideIndex += 1;
						const slide = slides[nextSlideIndex];
						const newSlidePositionRight = Math.abs(slide.carouselPosition) + slide.width;
						const newSlideOffset = newSlidePositionRight - Math.abs(currentSlidePosition);
						inView = newSlideOffset <= this.viewableWidth;
					}
				}
				return nextSlideIndex;
			},
			_getPrevSlideIndex(slidesToScroll) {
				const currentSlide = this.currentSlide;
				const { fullWidth, continuous } = this.config;
				let previousSlideIndex = currentSlide.index;
				if (continuous && currentSlide.index === 0) {
					previousSlideIndex = this.lastSlide.index;
				} else if (fullWidth) {
					previousSlideIndex = Math.max(currentSlide.index - slidesToScroll, 0);
				} else {
					const slides = this.slides;
					const currentSlidePosition = currentSlide.carouselPosition;
					const minSlideIndex = Math.max(currentSlide.index - slidesToScroll, 0);
					let inView = true;
					/* Iterate from current slide towards the first slide until you hit the minSlideIndex or until the next slide would push slides out of view */
					while (previousSlideIndex > minSlideIndex && inView) {
						previousSlideIndex -= 1;
						const newSlidePosition = slides[previousSlideIndex].carouselPosition;
						const newSlideOffset = Math.abs(currentSlidePosition) - Math.abs(newSlidePosition);
						inView = newSlideOffset < this.viewableWidth;
					}
					if (!inView) {
						previousSlideIndex += 1;
					}
				}
				return previousSlideIndex;
			},
			goToSlide(index, ignoreCallbacks = false) {  /* Ignore callbacks during init */
				this._fixPositionsIfNeeded();
				const { onSlideChange, onSlideLoad, scrollTime, onAfterSlideChange, infinite } = this.config;
				const newSlide = this.slides[this._getNewSlideIndex(index)];
				const prevSlide = this.currentSlide;
				const callbackParams = [newSlide.index, newSlide.$element, prevSlide.index, prevSlide.$element];
				const hasValidAfterSlideChange = ui.isValidCallback(onAfterSlideChange);
				if (!ignoreCallbacks && ui.isValidCallback(onSlideChange)) {
					onSlideChange.apply(this.$element, callbackParams);
				}
				this._loadSlide(newSlide, ignoreCallbacks); /* Update classes/disable buttons */
				this._disableBtns();
				if (!ignoreCallbacks && (!this.config.autoscroll || this.autoscroll.state !== 'playing')) {
					this.$element.focus();
				}
				if (ui.isValidCallback(onSlideLoad) && !newSlide.carouselLoaded) {
					onSlideLoad.apply(this.$element, callbackParams);
					newSlide.carouselLoaded = true;
				}
				if (infinite && !hasValidAfterSlideChange) {
					ui.warn(`onAfterSlideChange callback must be implemented for an infinite carousel (${this.carouselId})`);
				}
				this.timeout.animate = setTimeout(() => {
					this.$element.removeClass('carouselAnimating');
					if (ignoreCallbacks) {
						return;
					}
					if (hasValidAfterSlideChange) {
						const nextSlide = onAfterSlideChange.apply(this.$element, callbackParams);
						if (infinite) {
							if (nextSlide === '') { // DOM updates are handled externally
								this._updateInfiniteSlides();
							} else if (newSlide) {
								this._loadInfiniteSlide(newSlide, nextSlide);
							} else {
								this._disableBtns(true);
							}
						}
					}
				}, scrollTime);
			},
			_initAccessibility() {
				this.$inner.find('.carouselSlide')
					.attr('aria-hidden', true)
					.find(ui.tabbableTags)
					.add(this.$element)
					.attr('tabindex', -1);
			},
			_initSlides() {
				const carouselHeight = this.config.carouselHeight;
				const $slides = this.$element.find('.carouselSlide');
				const slides = [];
				/* Set height if passed in config */
				if (carouselHeight !== 'auto' && carouselHeight !== 'varied') {
					$slides.css('min-height', carouselHeight);
				}
				$slides.each((i, el) => {
					const $slideElement = $(el);
					const slide = {
						carouselLoaded: false,
						index: i,
						$element: $slideElement,
						width: $slideElement.outerWidth(false),
						positionLeft: $slideElement.position().left,
						marginLeft: parseInt($slideElement.css('margin-left'), 10)
					};
					slides.push(slide);
				});
				return slides;
			},
			_loadInfiniteSlide(newSlide, nextSlide) {
				const innerChildren = this.$inner.children();
				if (newSlide.index === 2) {
					$(innerChildren[0]).remove();
					this.$inner.append(nextSlide);
				} else if (newSlide.index === 0) {
					$(innerChildren[2]).remove();
					this.$inner.prepend(nextSlide);
				}
				this._updateInfiniteSlides();
			},
			_loadSlide(nextSlide, ignoreAnimation = false) { /* Update current slide and all appropriate classes */
				const activeBtnClass = this.config.activeBtnClass;
				const currentSlide = this.currentSlide;
				const forCarousel = `[data-carousel-id="${this.carouselId}"]`;
				const $currentSlideBtns = $(`${forCarousel}[data-carousel-slide="${currentSlide.index}"]`);
				const $nextSlideBtns = $(`${forCarousel}[data-carousel-slide="${nextSlide.index}"]`);
				$currentSlideBtns.removeClass(activeBtnClass);
				$nextSlideBtns.addClass(activeBtnClass);
				this._updateSlidesState(nextSlide, currentSlide);
				/* animate to new slide */
				if (!ignoreAnimation) {
					this.$element.addClass('carouselAnimating');
				}
				const cssObj = {};
				if (this.config.carouselHeight === 'varied') {
					cssObj.height = nextSlide.$element.outerHeight(true);
				}
				this.$inner.css(this._generateAnimationCss(nextSlide.carouselPosition, cssObj, ignoreAnimation));
				this.currentSlide = nextSlide;
			},
			pause(tempState) {
				if (tempState) {
					this.autoscroll.state = tempState;
				} else {
					this.autoscroll.state = 'paused';
					this.controls.$pausePlayBtns
						.removeClass('iconPause')
						.addClass('iconPlay')
						.html(`<span class="hideVisually">${ui.localize(carousel.staticProperties.langSets, 'playBtn')}</span>`);
				}
				clearInterval(this.autoscroll.interval);
				this.$element
					.removeClass('carouselPlaying')
					.addClass('carouselPaused')
					.trigger('pause.carousel');
			},
			play() {
				this.autoscroll.state = 'playing';
				this.controls.$pausePlayBtns
					.removeClass('iconPlay')
					.addClass('iconPause')
					.html(`<span class="hideVisually">${ui.localize(carousel.staticProperties.langSets, 'pauseBtn')}</span>`);
				const { autoscroll, scrollTime } = this.config;
				this.autoscroll.interval = setInterval(() => {
					if (this.config.continuous || this.currentSlide.index !== this.lastSlide.index) {
						this.goToSlide('next');
					} else {
						this.pause();
					}
				}, autoscroll + scrollTime);
				this.$element
					.removeClass('carouselPaused')
					.addClass('carouselPlaying')
					.trigger('play.carousel');
			},
			_savePositions() {
				const { slides, viewableWidth } = this;
				let lastSlide = slides[slides.length - 1];
				const carouselWidth = lastSlide.positionLeft + lastSlide.$element.outerWidth(false);
				if (carouselWidth <= viewableWidth) {
					slides.map(slide => {
						slide.carouselPosition = 0;
						return slide;
					});
				} else {
					const maxLeftPositionForGalleries = this.$inner.outerWidth(false) - carouselWidth - lastSlide.marginLeft;
					slides.every(slide => {
						const position = -(slide.positionLeft + slide.marginLeft);
						if (this.config.fullWidth) {
							slide.carouselPosition = position / viewableWidth * 100;
						} else if (Math.abs(position) < Math.abs(maxLeftPositionForGalleries)) {
							slide.carouselPosition = position;
						} else {
							/* If the slide would be positioned above maxLeftPositionForGalleries set it as the last slide and exit loop */
							slide.carouselPosition = maxLeftPositionForGalleries;
							lastSlide = slide; /* Cache last navigable slide */
							if (this.currentSlide.index > slide.index) {
								/* Update current slide to lastSlideIndex */
								this.currentSlide = slide;
							}
							return false;
						}
						return true;
					});
				}
				this.config.carouselWidth = carouselWidth;
				this.lastSlide = lastSlide;
				this._initAccessibility();
			},
			setPositions() {
				/*	This should ONLY be needed if the width of the gallery slides starts as less
					than than the width of the viewable area, then a DOM resize event happens
					making the slides wider than the viewable area. Without this, the next
					buttons remain disabled. */
				this._fixPositionsIfNeeded();
			},
			_setSlideState($slide, isActive) {
				const { activeSlideClass } = this.config;
				if (isActive) {
					$slide
						.addClass(activeSlideClass)
						.removeAttr('aria-hidden')
						.find(ui.tabbableTags)
						.removeAttr('tabindex');
				} else {
					$slide
						.removeClass(activeSlideClass)
						.attr('aria-hidden', true)
						.find(ui.tabbableTags)
						.attr('tabindex', -1);
				}
			},
			_updateInfiniteSlides() {
				this.slides = this._initSlides();
				this._savePositions(); /* rebuilds slides object */
				this._loadSlide(this.slides[1], true);
			},
			_updateSlidePositioning() {
				const slides = this.slides;
				this.viewableWidth = this.$inner.outerWidth(false);
				for (let i = slides.length - 1; i >= 0; i--) {
					const slide = slides[i];
					slide.width = slide.$element.outerWidth(false);
					slide.positionLeft = slide.$element.position().left;
					slide.marginLeft = parseInt(slide.$element.css('margin-left'), 10);
				}
			},
			_updateSlidesState(nextSlide, prevSlide) {
				const newPosition = nextSlide.positionLeft;
				if (this.config.fullWidth) {
					this._setSlideState(prevSlide.$element, false);
					this._setSlideState(nextSlide.$element, true);
				} else {
					const rightEdge = newPosition + this.$inner.outerWidth(false);
					this.slides.forEach(slide => {
						const slidePositionRight = slide.positionLeft + slide.width;
						const isActive = slide.positionLeft >= newPosition && slidePositionRight < rightEdge;
						this._setSlideState(slide.$element, isActive);
					});
				}
			}
		},
		staticProperties: {
			defaults: {
				activeBtnClass: 'active', /* Class for active button (pagination) */
				activeSlideClass: 'carouselActiveSlide', /* Class for active slide */
				autoscroll: 0, /* Delay time between autoscroll slides (0 = autoscroll off) */
				carouselHeight: 'auto', /* ('auto' | 'varied' | CSS value ) 'auto' = No height is set, which means the carousel will default to the height of the tallest slide; 'varied' = Height varies with each slide and animates on each scroll; CSS value = Enter a CSS value (in px, em, %, etc) to manually set height of carousel; */
				continuous: false, /* Set true to make slides continue to first slide at the end */
				disabledBtnClass: 'disabled', /* Class for disabled button (pagination) */
				disableTouchEvents: false,
				firstSlide: 0, /* Set first slide */
				fullWidth: true, /* Set true for a full width carousel (one slide in view at a time) or false for a floated gallery (floated / multiple slides in view) */
				infinite: false, /* Shows 3 slides and scrolls infinitely; you are responsible for dynamically populating slide content. */
				onAfterSlideChange: false, /* function (slideIndex, $slide, previousSlideIndex, $previousSlide) { } Callback that occurs AFTER each time a slide changes */
				onSlideChange: false, /* function (slideIndex, $slide, previousSlideIndex, $previousSlide) { } Callback that occurs each time a slide changes */
				onSlideLoad: false, /* function (slideIndex, $slide, previousSlideIndex, $previousSlide) { } Callback for when the slide loads */
				pagination: null, /* Set to 'numbers' or 'dots' to turn on pagination */
				pausePlayBtnClass: 'carouselPausePlayBtn', /* Class for pause / play toggle button */
				playOnLoad: true, /* Set false to pause autoscroll on load */
				scrollTime: 400, /* Animation time to scroll */
				showPausePlayBtns: false, /* Set true to turn on pause / play button (if autoscroll is set) */
				showPreviews: false, /* Set true to show a preview (64px by default) of the previous and next slide */
				showPrevNextBtns: true, /* Set false to turn off prev / next buttons */
				slidesPerScroll: 'page' /* Accepts 'page' or number. Only applies to galleries with 'fullWidth: false. 'page' = scrolls the number of slides fully visible in the viewport. number = scroll a specific number of slides at a time. */
			},
			langSets: {
				de: {
					nextBtn: 'N&auml;chstes Dia',
					pauseBtn: 'Diashow anhalten',
					playBtn: 'Diashow abspielen',
					previousBtn: 'Vorheriges Dia',
					slide: 'Dia'
				},
				en: {
					nextBtn: 'Next slide',
					pauseBtn: 'Pause slideshow',
					playBtn: 'Play slideshow',
					previousBtn: 'Previous slide',
					slide: 'Slide'
				},
				es: {
					nextBtn: 'Diapositiva siguiente',
					pauseBtn: 'Pausar presentacio&#769n',
					playBtn: 'Reproducir presentacio&#769n',
					previousBtn: 'Diapositiva anterior',
					slide: 'Diapositiva'
				},
				fr: {
					nextBtn: 'Diapositive suivante',
					pauseBtn: 'Suspendre le diaporama',
					playBtn: 'Lancer le diaporama',
					previousBtn: 'Diapositive pre&#769ce&#769dente',
					slide: 'Diapositive'
				},
				it: {
					nextBtn: 'Diapositiva successiva',
					pauseBtn: 'Sospendi presentazione',
					playBtn: 'Riproduci presentazione',
					previousBtn: 'Diapositiva precedente',
					slide: 'Diapositiva'
				},
				sv: {
					nextBtn: 'N&auml;chstes Dia',
					pauseBtn: 'Pausa bildspel',
					playBtn: 'Spela upp bildspel',
					previousBtn: 'Tidigare bild',
					slide: 'Bild'
				}
			}
		}
	};
	$.fn.carousel = function init(options, ...args) {
		const isMethodCall = typeof options === 'string';
		if (isMethodCall) {
			const instance = this.data('carouselInstance');
			if (!instance) {
				ui.warn(`Cannot call methods on carousel prior to initialization; attempted to call method "${options}".`);
				return false;
			}
			if (typeof instance[options] !== 'function' || options.indexOf('_') === 0) {
				ui.warn(`No such method "${options}" for carousel.`);
				return false;
			}
			const methodValue = instance[options](...args);
			if (methodValue !== instance && methodValue !== undefined) {
				return methodValue && methodValue.jquery ? this.pushStack(methodValue.get()) : methodValue;
			}
		} else {
			carousel.create($(this), options);
		}
		return this;
	};
	/* Expose testable methods */
	$.carousel = {
		getTest() {
			return Object.assign({}, carousel);
		}
	};
})(jQuery);
